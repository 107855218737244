var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"visible":"","scrollable":"","centered":"","title":"Объединение пациентов","hide-footer":"","size":"lg","no-close-on-backdrop":""},on:{"hidden":_vm.onClose,"ok":_vm.onClose}},[_c('div',{staticClass:"container policy-container"},[_c('div',{staticClass:"clinics-form-fields d-flex align-items-center"},[_c('base-input',{staticClass:"crm-form-field mr-0",attrs:{"error":_vm.$v.personIdTo.$error,"errors":_vm.errorsValidation.personIdTo,"input-style":{
          padding: '11px 15px 12px',
          resize: 'none',
          backgroundColor: '#ffffff',
        },"label":"Куда переносим данные","placeholder":"Введите id учетной записи"},model:{value:(_vm.$v.personIdTo.$model),callback:function ($$v) {_vm.$set(_vm.$v.personIdTo, "$model", $$v)},expression:"$v.personIdTo.$model"}}),_c('b-icon',{staticClass:"mx-4 transfer-arrow",attrs:{"icon":"arrow-left","font-scale":"1.5","animation":"cylon"}}),_c('base-input',{staticClass:"crm-form-field",attrs:{"error":_vm.$v.personIdFrom.$error,"errors":_vm.errorsValidation.personIdFrom,"input-style":{
          padding: '11px 15px 12px',
          resize: 'none',
          backgroundColor: '#ffffff',
        },"label":"Откуда переносим данные","placeholder":"Введите id учетной записи"},model:{value:(_vm.$v.personIdFrom.$model),callback:function ($$v) {_vm.$set(_vm.$v.personIdFrom, "$model", $$v)},expression:"$v.personIdFrom.$model"}})],1),_c('div',{staticClass:"crm-description",class:{'mt-5': this.$v.$error}},[_vm._v(" В основную учетную запись перенесутся все данные "),_c('br'),_vm._v(" из дочерней учетной записи ")]),_c('div',[_c('b-form-group',{attrs:{"label":"Комментарий","label-class":"required","state":!_vm.$v.comment.$error,"invalid-feedback":_vm.errorsValidation.comment[0]}},[_c('b-form-textarea',{attrs:{"placeholder":"Введите текст","rows":"1","max-rows":"3","size":"sm"},model:{value:(_vm.$v.comment.$model),callback:function ($$v) {_vm.$set(_vm.$v.comment, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.comment.$model"}})],1)],1),_c('div',{staticClass:"crm-wrapper-buttons"},[_c('b-button',{staticClass:"crm-button mr-5 px-3",attrs:{"variant":"danger","type":_vm.$const.PRIMARY_BUTTON},on:{"click":_vm.onClose}},[_vm._v(" Отменить ")]),_c('b-button',{staticClass:"crm-button",attrs:{"variant":"primary","type":_vm.$const.PRIMARY_BUTTON,"loading":_vm.isLoading,"disabled":_vm.isDisabled},on:{"click":_vm.onClickTransfer}},[_vm._v(" Объединить ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }