import { required } from 'vuelidate/lib/validators';

export const createValidation = (fields) => {
  const validations = {};
  fields.forEach((field) => {
    validations[field] = { required };
  });
  return validations;
};

export const createErrorsValidation = (v, fields, messages) => {
  const errors = {};
  fields.forEach((field) => {
    errors[field] = [];
    if (!v[field].required) {
      errors[field].push(messages[field] || 'Поле не может быть пустым');
    }
  });
  return errors;
};
